import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
const firebaseConfig = {
    apiKey: "AIzaSyCAmmhBIFZPwRrg3jOO-17MPHqQbn5fg3g",
    authDomain: "wilford-react.firebaseapp.com",
    projectId: "wilford-react",
    storageBucket: "wilford-react.appspot.com",
    messagingSenderId: "413906121090",
    appId: "1:413906121090:web:12766cacdd8ff08dca5f9b",
    measurementId: "G-PECM8NHQQE"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export default db;